html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4495cf;
}

input {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* leaflet classes */
.map-marker > svg {
  fill: #4495cf;
}
.map-marker-liked > svg {
  fill: hotpink;
  height: 32px;
  width: 32px;
}
.map-marker-current > svg {
  fill: green;
}
